* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

a,
a:visited,
a:active {
    text-decoration: none;
    font-weight: bold;
    color: darkorange;
}
a:hover,
a:focus {
    color: orangered;
}

button {
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
}

select {
    padding: 2px 4px;
}

h1, h2 {
    margin: 16px 0 10px;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    padding-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: 1px dashed black;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.settings button:not(:first-child) {
    margin-left: 5px;
}

.settings-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    width: 100%;
    max-width: 400px;
}
.settings-section:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, .2);
}

.settings-section-label {
    font-weight: bold;
    color: #333;
}

.grid {
    padding: 4px;
    border: 1px solid black;
    display: grid;
    width: fit-content;
}

@font-face {
    font-family: "color-emoji";
    src: local("Apple Color Emoji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji");
}

.cell {
    cursor: pointer;
    font-family: color-emoji;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.cell-state-0:hover {
    background: black;
}

.cell-state-1:hover {
    background: black;
}

.cell-visibility-false {
    display: none;
}
